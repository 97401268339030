import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useRoutes, redirect } from "react-router-dom";
import './loginPage.css';  
import Logo_dark from "../../assets/CodePRo.png";// Import the logo image
import illustration from '../../assets/Moneyverse Home Office.png';  // Import another image
import { validEmail, validPassword } from '../regex/regex';
import jQuery from 'jquery';
import { Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import Approver from '../Approver/Approver';

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken' 
const LoginPage =()=>{
  const[email,setEmail]=useState("")
  const[password,setPassword]=useState("")
  const[emailErr,setemailErr]=useState(false)
  const[passErr,setpassErr]=useState(false)
  const[responsecr,setResponse]=useState("")
  const navigate = useNavigate();
  const setCSRF = ()=>{
    let csrfURL = "https://codepro.insa.gov.et/setcsrf/";
    const response = axios.get(csrfURL);
    console.log(response)
    return
  };
  const get_cookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    console.log(cookieValue)
    return cookieValue;
  };
  
  
  /*useEffect(()=>{
    let csrfURL = 'https://172.20.136.72:8000/setcsrf/';
    const response = axios.get(csrfURL);
    console.log(response)
  })*/
    let axioConfig={
      headers:{
        'X_CSRFToken':get_cookie('csrftoken')
      },
      Credential:'include',
      withCredentials:true,
    }
  
 
  const submitLogin=async(e)=>{
    e.preventDefault();

    /*let csrfURL = 'http://127.0.0.1:8000/setcsrf/';
    const response = await axios.get(csrfURL);
    
    setCSRF()*/
    console.log(get_cookie('csrftoken'))
    console.log(email)
    console.log(password)

    
    console.log(responsecr)
    axios.post("https://codepro.insa.gov.et/login/",{
        csrfmiddlewaretoken: get_cookie('csrftoken'),
        email: email,
        password: password,
      },axioConfig)
      .then((res)=>{
        console.log(res)
        if(res.status===200){
          console.log("login success")
          console.log(res)
          if(res.data[0]['role']===1){
            sessionStorage.setItem('loged','Admin')
            console.log(res.data[0]['userid'])
            navigate("/Admin",{state:{id:res.data[0]['userid']}})
            /*const root = ReactDOM.createRoot(document.getElementById('root'));
            root.render(<Admin/>);*/
          }
          else if (res.data[0]['role']===3){
            sessionStorage.setItem('loged','Customer')
            console.log(res.data[0]['userid'])
            navigate("/Settings",{state:{id:res.data[0]['userid']}})
            //const root = ReactDOM.createRoot(document.getElementById('root'));
            //root.render(<Settings id={res.data[0]['userid']}/>); 
          }
          else if (res.data[0]['role']===2){
            sessionStorage.setItem('loged','Approver')
            console.log(res.data[0]['userid'])
            navigate("/Approver",{state:{id:res.data[0]['userid']}})
            /*const root = ReactDOM.createRoot(document.getElementById('root'));
            root.render(<Approver/>); */
          }           
        }
        else{
          console.log("error login")
          console.log(res.status)
          console.log(document.getElementById('error').innerText)
          document.getElementById('error').innerText="Incorrect Username or Password"
          
        }
        
    })
    .catch((err) =>{
      document.getElementById('error').innerText="Incorrect Username or Password"
    });
    
  };
  
  return (
      <div className='bodylogin'>
        <div className="containerlogin">
          
          <div className="leftsection">
            <div className="logo-login">
                <a href="/">
                    <img id='logimg' src={Logo_dark} alt="Logo" class="logo"/>
                </a>
            </div>
            <h1>Welcome Back</h1>
            <p>By signing up, you agree to the <a href="#">Terms of use</a> and <a href="#">Privacy Policy</a>.</p>
            <p id="error"></p>
            <form onSubmit={submitLogin} >
             
              <label htmlFor="username">Email</label>
              <input type="text" id="username2" name="email" value={email} onChange={e=>setEmail(e.target.value)} />

              <label htmlFor="password">Password</label>
              <input type="password" id="passwordlo" name="password" value={password} onChange={e=>setPassword(e.target.value)} />

              <button id="btnlogin" type="submit">Sign in</button>
            </form>
            <div className="forgot-password">
              <Link to="/forgotPassword"><button className='fgbtn'>Forgot password</button></Link>
            </div>
            <div className="bottom-right">
              <p>Don't have an account?</p> <Link to="/signup"><button className='signupb'>Sign Up</button></Link>
            </div>
            
          </div>

          <div className="rightsection">
            <div className="plan-details">
              {/* Use the imported illustration */}
               <img src={illustration} alt="Security Illustration"/>
              <p>Your plan includes:</p>
              <ul>
                <li>• Limited projects and resources</li>
                <li>• Limited upload size</li>
                <li>• Limited storage</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
  );
  //};

}

export default LoginPage;
